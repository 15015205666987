import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

export default function ReviewShipmentDialog(props) {
  const [open, setOpen] = React.useState(false);

  const handleButton = (value) => {
    if (typeof props.validate === 'function' && !props.validate()) {
      return false;
    }
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
  };

  const dialogButtonStyle = {
    padding: '4px 30px'
  };

  let buttonElement = { type: Button };
  if ( props.buttonElement ) {
    buttonElement = { type: props.buttonElement };
  }

  let listData = [];
  props.shipments.forEach( shipment => {
    const code = shipment.code;
    const tempData = shipment.keys.map( k => {
      return {
        code: code,
        mac: k
      };
    });
    listData = [
      ...listData,
      ...tempData
    ];
  });

  return (
    <React.Fragment>
      <buttonElement.type
        onClick={handleButton}
        startIcon={
          props.loading ?
          <CircularProgress color="info" size={20} thickness={5} sx={{ ml: '-20px' }}/>
          :
          undefined
        }
        disabled={props.loading}
        {...props.buttonProps}
      >
        {props.buttonText}
        {props.buttonProps.children}
      </buttonElement.type>
      {
        props.additionalButtons &&
        props.additionalButtons.map(btn => {
          let btnElement = { type: Button };
          if ( btn.buttonElement ) {
            btnElement = { type: btn.buttonElement };
          }
          return (
            <btnElement.type onClick={btn.handleButton} disabled={props.loading} {...btn.buttonProps}>
              {btn.buttonText}
              {btn.buttonProps.children}
            </btnElement.type>
          );
        })
      }
      <Dialog
        PaperProps={{ style: { backgroundColor: 'white', color: 'black' } }}
        scroll={'paper'}
        onClose={handleClose}
        open={open}
      >
        { props.dialogTitle && <DialogTitle>{props.dialogTitle}</DialogTitle> }
        <DialogContent dividers={true}>
          <DialogContentText color="inherit" id="review-shipment-dialog">
            {props.dialogText}
          </DialogContentText>
          <List sx={{ width: '100%' }}>
            { listData.map( data => {
              return (
                <ListItem secondaryAction={data.mac}>
                  <ListItemText primary={data.code} />
                </ListItem>
              );
            })}
          </List>
        </DialogContent>
        <DialogActions style={{ flex: 'space-around' }}>
          <Button
            style={dialogButtonStyle}
            variant="contained"
            onClick={() => {
              let result = true;
              if (props.id) {
                result = props.handleDialogClickYes(props.id);
              } else {
                result = props.handleDialogClickYes();
              }
              if ( result === undefined || result ) {
                setOpen(false);
              }
            }}
          >
            {props.dialogYesText}
          </Button>
          <Button style={dialogButtonStyle} variant="outlined" onClick={handleClose}>
            {props.dialogNoText}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}