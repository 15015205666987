import { ajaxCall, ajaxCallBlob, formRequestData } from './ajaxService';

const constants = window.getConfig;
const getAdminProductsUrl = `${constants.ajax.baseUrl}/admin/products`;
const getAdminOptionsUrl = `${constants.ajax.baseUrl}/admin/options`;
const getAdminShipmentUrl = `${constants.ajax.baseUrl}/admin/shipment`;
const getAdminDistributorsUrl = `${constants.ajax.baseUrl}/admin/distributors`;
const getAdminDistributorInvoiceUrl = `${constants.ajax.baseUrl}/admin/distributors/invoice`;
const getAdminChangePwdUrl = `${constants.ajax.baseUrl}/admin/pwd`;
const getAdminFirmwaresUrl = `${constants.ajax.baseUrl}/admin/firmwares`;
const getAdminCustomFirmwaresUrl = `${constants.ajax.baseUrl}/admin/cfirmwares`;
const getConfirmCompanyReplacementAdminUrl = `${constants.ajax.baseUrl}/admin/inventories/confirmCompanyReplacement`;
const getCancelCompanyReplacementAdminUrl = `${constants.ajax.baseUrl}/admin/inventories/cancelCompanyReplacement`;
const getCompanyReplacementAdminUrl = `${constants.ajax.baseUrl}/admin/inventories/companyReplacement`;
const getCompanyProductDiscountsUrl = `${constants.ajax.baseUrl}/admin/discounts/products`;
const getCompanyProductDiscountSaveUrl = `${constants.ajax.baseUrl}/admin/discounts/products/save`;
const getCompanyProductDiscountDeleteUrl = `${constants.ajax.baseUrl}/admin/discounts/products/delete`;
const getCompanyProductOptionDiscountAutocompleteUrl = `${constants.ajax.baseUrl}/admin/discounts/options/autocomplete`;
const getCompanyProductOptionDiscountsUrl = `${constants.ajax.baseUrl}/admin/discounts/options`;
const getCompanyProductOptionDiscountSaveUrl = `${constants.ajax.baseUrl}/admin/discounts/options/save`;
const getCompanyProductOptionDiscountDeleteUrl = `${constants.ajax.baseUrl}/admin/discounts/options/delete`;
const getAdminCompanyMonthySalesUrl = `${constants.ajax.baseUrl}/admin/company/monthly/sales`;
const getAdminCompanyMonthySalesTotalUrl = `${constants.ajax.baseUrl}/admin/company/monthly/salesTotal`;
const cancelSaleAdminUrl = `${constants.ajax.baseUrl}/admin/inventories/cancelSale`;

const ajaxGetAdminCompanyMonthySales = (params) =>
  new Promise((res, rej) => {
    ajaxCall(getAdminCompanyMonthySalesUrl, formRequestData(constants.ajax.methods.get, true))
      .then(res)
      .catch(rej);
  });

const ajaxGetAdminCompanyMonthySalesTotal = (params) =>
  new Promise((res, rej) => {
    ajaxCall(getAdminCompanyMonthySalesTotalUrl, formRequestData(constants.ajax.methods.get, true))
      .then(res)
      .catch(rej);
  });

const ajaxGetAdminProducts = (params) =>
  new Promise((res, rej) => {
    if (params.ProductId) {
      ajaxCall(getAdminProductsUrl + `/${params.ProductId}`, formRequestData(constants.ajax.methods.get, true))
        .then(res)
        .catch(rej);
    } else {
      ajaxCall(getAdminProductsUrl, formRequestData(constants.ajax.methods.post, true, false, params)).then(res).catch(rej);
    }
  });

const ajaxGetAdminOptions = (params) =>
  new Promise((res, rej) => {
    if (params.OptionId) {
      ajaxCall(getAdminOptionsUrl + `/${params.OptionId}`, formRequestData(constants.ajax.methods.get, true))
        .then(res)
        .catch(rej);
    } else {
      ajaxCall(getAdminOptionsUrl, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
    }
  });

const ajaxSaveAdminOption = (params) =>
  new Promise((res, rej) => {
    ajaxCall(getAdminOptionsUrl + `/save`, formRequestData(constants.ajax.methods.post, true, false, params))
      .then(res)
      .catch(rej);
  });

const ajaxGetAdminProductOptions = (params) =>
  new Promise((res, rej) => {
    ajaxCall(getAdminProductsUrl + `/options/${params.ProductId}`, formRequestData(constants.ajax.methods.get, true))
      .then(res)
      .catch(rej);
  });

const ajaxSaveAdminProductOption = (params) =>
  new Promise((res, rej) => {
    ajaxCall(getAdminProductsUrl + `/options/save/${params.product_code_id}`, formRequestData(constants.ajax.methods.post, true, false, params))
      .then(res)
      .catch(rej);
  });

const ajaxDisableAdminProductOption = (params) =>
  new Promise((res, rej) => {
    ajaxCall(getAdminProductsUrl + `/options/disable/${params.id}`, formRequestData(constants.ajax.methods.get, true))
      .then(res)
      .catch(rej);
  });

const ajaxEnableAdminProductOption = (params) =>
  new Promise((res, rej) => {
    ajaxCall(getAdminProductsUrl + `/options/enable/${params.id}`, formRequestData(constants.ajax.methods.get, true))
      .then(res)
      .catch(rej);
  });

const ajaxSaveAdminProduct = (params) =>
  new Promise((res, rej) => {
    ajaxCall(getAdminProductsUrl + '/save', formRequestData(constants.ajax.methods.post, true, false, params, true))
      .then(res)
      .catch(rej);
  });

const ajaxDisableAdminProduct = (params) =>
  new Promise((res, rej) => {
    ajaxCall(getAdminProductsUrl + `/disable/${params.ProductId}`, formRequestData(constants.ajax.methods.get, true))
      .then(res)
      .catch(rej);
  });

const ajaxEnableAdminProduct = (params) =>
  new Promise((res, rej) => {
    ajaxCall(getAdminProductsUrl + `/enable/${params.ProductId}`, formRequestData(constants.ajax.methods.get, true))
      .then(res)
      .catch(rej);
  });

const ajaxGetAdminShipments = () =>
  new Promise((res, rej) => {
    ajaxCall(getAdminShipmentUrl, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
  });

const ajaxAddAdminShipment = (params) =>
  new Promise((res, rej) => {
    ajaxCall(getAdminShipmentUrl + `/add/${params.company_id}`, formRequestData(constants.ajax.methods.post, true, false, params, true))
      .then(res)
      .catch(rej);
  });

const ajaxGetAdminDistributorInvoice = (params, filename) =>
  new Promise((res, rej) => {
    ajaxCallBlob(getAdminDistributorInvoiceUrl + `/${params.companyId}/${params.datetime}`, formRequestData(constants.ajax.methods.get, true))
      .then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.target = '_blank';
        a.download = filename;
        a.click();
        res();
      })
      .catch(rej);
  });

const ajaxGetAdminDistributors = (params) =>
  new Promise((res, rej) => {
    if (params.CompanyId) {
      ajaxCall(getAdminDistributorsUrl + `/${params.CompanyId}`, formRequestData(constants.ajax.methods.get, true))
        .then(res)
        .catch(rej);
    } else {
      ajaxCall(getAdminDistributorsUrl, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
    }
  });

const ajaxSaveAdminDistributor = (params) =>
  new Promise((res, rej) => {
    ajaxCall(getAdminDistributorsUrl + '/save', formRequestData(constants.ajax.methods.post, true, false, params, true))
      .then(res)
      .catch(rej);
  });

const ajaxGetAdminDistributorsAutocomplete = () =>
  new Promise((res, rej) => {
    ajaxCall(getAdminDistributorsUrl + '/autocomplete', formRequestData(constants.ajax.methods.get, true))
      .then(res)
      .catch(rej);
  });

const ajaxDisableAdminDistributor = (params) =>
  new Promise((res, rej) => {
    if (params.id) {
      ajaxCall(getAdminDistributorsUrl + `/disable/${params.id}`, formRequestData(constants.ajax.methods.put, true))
        .then(res)
        .catch(rej);
    }
  });

const ajaxEnableAdminDistributor = (params) =>
  new Promise((res, rej) => {
    if (params.id) {
      ajaxCall(getAdminDistributorsUrl + `/enable/${params.id}`, formRequestData(constants.ajax.methods.put, true))
        .then(res)
        .catch(rej);
    }
  });

const ajaxSaveAdminPwd = (params) =>
  new Promise((res, rej) => {
    if (params.username && params.password) {
      ajaxCall(getAdminChangePwdUrl, formRequestData(constants.ajax.methods.post, true, false, params)).then(res).catch(rej);
    } else {
      res();
    }
  });

const ajaxGetAdminFirmwares = (params) =>
  new Promise((res, rej) => {
    if (params.FirmwareId) {
      ajaxCall(getAdminFirmwaresUrl + `/${params.FirmwareId}`, formRequestData(constants.ajax.methods.get, true))
        .then(res)
        .catch(rej);
    } else {
      ajaxCall(getAdminFirmwaresUrl, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
    }
  });

const ajaxSaveAdminFirmware = (params) =>
  new Promise((res, rej) => {
    ajaxCall(getAdminFirmwaresUrl + '/save', formRequestData(constants.ajax.methods.post, true, false, params, true))
      .then(res)
      .catch(rej);
  });

const ajaxDeleteAdminFirmware = (params) =>
  new Promise((res, rej) => {
    ajaxCall(getAdminFirmwaresUrl + `/delete/${params.id}`, formRequestData(constants.ajax.methods.put, true))
      .then(res)
      .catch(rej);
  });

const ajaxGetAdminCustomFirmwares = (params) =>
  new Promise((res, rej) => {
    if (params.FirmwareId) {
      ajaxCall(getAdminCustomFirmwaresUrl + `/${params.FirmwareId}`, formRequestData(constants.ajax.methods.get, true))
        .then(res)
        .catch(rej);
    } else {
      ajaxCall(getAdminCustomFirmwaresUrl, formRequestData(constants.ajax.methods.get, true)).then(res).catch(rej);
    }
  });

const ajaxSaveAdminCustomFirmware = (params) =>
  new Promise((res, rej) => {
    ajaxCall(getAdminCustomFirmwaresUrl + '/save', formRequestData(constants.ajax.methods.post, true, false, params, true))
      .then(res)
      .catch(rej);
  });

const ajaxDeleteAdminCustomFirmware = (params) =>
  new Promise((res, rej) => {
    ajaxCall(getAdminCustomFirmwaresUrl + `/delete/${params.id}`, formRequestData(constants.ajax.methods.put, true))
      .then(res)
      .catch(rej);
  });

  const ajaxConfirmCompanyReplacement = (params) =>
  new Promise((res, rej) => {
    ajaxCall(getConfirmCompanyReplacementAdminUrl + `/${params.companyId}`, formRequestData(constants.ajax.methods.post, true, false, params))
      .then(res)
      .catch(rej);
  });

const ajaxCancelCompanyReplacement = (params) =>
  new Promise((res, rej) => {
    ajaxCall(getCancelCompanyReplacementAdminUrl + `/${params.companyId}`, formRequestData(constants.ajax.methods.post, true, false, params))
      .then(res)
      .catch(rej);
  });

const ajaxGetReplacementCompany = () =>
  new Promise((res, rej) => {
    ajaxCall(getCompanyReplacementAdminUrl, formRequestData(constants.ajax.methods.get, true))
      .then(res)
      .catch(rej);
  });

const ajaxGetCompanyProductDiscounts = (params) =>
  new Promise((res, rej) => {
      ajaxCall(getCompanyProductDiscountsUrl + `/${params.companyId}`, formRequestData(constants.ajax.methods.get, true))
      .then(res)
      .catch(rej);
  });

const ajaxSaveCompanyProductDiscount = (params) =>
  new Promise((res, rej) => {
    ajaxCall(getCompanyProductDiscountSaveUrl, formRequestData(constants.ajax.methods.post, true, false, params))
      .then(res)
      .catch(rej);
  });

const ajaxDeleteCompanyProductDiscount = (params) =>
  new Promise((res, rej) => {
      ajaxCall(getCompanyProductDiscountDeleteUrl + `/${params.id}`, formRequestData(constants.ajax.methods.put, true))
      .then(res)
      .catch(rej);
  });

const ajaxGetCompanyProductOptionAutocompleteDiscount = () =>
  new Promise((res, rej) => {
      ajaxCall(getCompanyProductOptionDiscountAutocompleteUrl, formRequestData(constants.ajax.methods.get, true))
      .then(res)
      .catch(rej);
  });
const ajaxGetCompanyProductOptionDiscounts = (params) =>
  new Promise((res, rej) => {
      ajaxCall(getCompanyProductOptionDiscountsUrl + `/${params.companyId}`, formRequestData(constants.ajax.methods.get, true))
      .then(res)
      .catch(rej);
  });

const ajaxSaveCompanyProductOptionDiscount = (params) =>
  new Promise((res, rej) => {
    ajaxCall(getCompanyProductOptionDiscountSaveUrl, formRequestData(constants.ajax.methods.post, true, false, params))
      .then(res)
      .catch(rej);
  });

const ajaxDeleteCompanyProductOptionDiscount = (params) =>
  new Promise((res, rej) => {
      ajaxCall(getCompanyProductOptionDiscountDeleteUrl + `/${params.id}`, formRequestData(constants.ajax.methods.put, true))
      .then(res)
      .catch(rej);
  });

const ajaxCancelSale = (params) =>
  new Promise((res, rej) => {
    ajaxCall(cancelSaleAdminUrl + `/${params.id}`, formRequestData(constants.ajax.methods.post, true, false, params))
      .then(res)
      .catch(rej);
  });

export {
  ajaxGetAdminProducts,
  ajaxGetAdminShipments, ajaxAddAdminShipment,
  ajaxGetAdminOptions, ajaxSaveAdminOption,
  ajaxGetAdminDistributorsAutocomplete,
  ajaxGetAdminDistributorInvoice,
  ajaxGetAdminDistributors, ajaxSaveAdminDistributor,
  ajaxDisableAdminDistributor, ajaxEnableAdminDistributor,
  ajaxSaveAdminProduct, ajaxDisableAdminProduct,
  ajaxEnableAdminProduct,
  ajaxGetAdminProductOptions, ajaxSaveAdminProductOption,
  ajaxDisableAdminProductOption, ajaxEnableAdminProductOption,
  ajaxSaveAdminPwd,
  ajaxGetAdminFirmwares, ajaxSaveAdminFirmware,
  ajaxGetAdminCustomFirmwares, ajaxSaveAdminCustomFirmware,
  ajaxDeleteAdminFirmware, ajaxDeleteAdminCustomFirmware,
  ajaxConfirmCompanyReplacement,
  ajaxCancelCompanyReplacement,
  ajaxGetReplacementCompany,
  ajaxGetCompanyProductDiscounts,
  ajaxSaveCompanyProductDiscount,
  ajaxDeleteCompanyProductDiscount,
  ajaxGetCompanyProductOptionAutocompleteDiscount,
  ajaxGetCompanyProductOptionDiscounts,
  ajaxSaveCompanyProductOptionDiscount,
  ajaxDeleteCompanyProductOptionDiscount,
  ajaxGetAdminCompanyMonthySales,
  ajaxGetAdminCompanyMonthySalesTotal,
  ajaxCancelSale
};
